<template>
  <div>
    <div class="d-flex mb-2 ml-2">
      <h4>
        Intro Screen Preview
      </h4>
    </div>
    <b-row>
      <b-col
        cols="12"
        md="4"
        lg="4"
        class="text-center"
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 1162.6 2324.3"
          style="enable-background:new 0 0 1162.6 2324.3; width: 75%; height: 75%;"
          xml:space="preserve"
        >
          <g id="background">
            <rect
              x="25"
              y="15"
              class="st0"
              width="1080"
              height="2287.2"
              :style="getFillColor(this.appData.introBackgroundColor)"
            />
          </g>
          <g id="frame_1_">
            <g id="frame">

              <image
                style="overflow:visible;"
                width="698"
                height="1403"
                xlink:href="../../../assets/images/svg/frame.png"
                transform="matrix(1.6671 0 0 1.6567 -0.4198 -0.3542)"
              />
            </g>
          </g>
          <g id="image">

            <image
              style="overflow:visible;"
              width="512"
              height="512"
              xlink:href="../../../assets/images/svg/intro.png"
              transform="matrix(1.6567 0 0 1.6326 144.7448 454.4609)"
            />
          </g>
          <g id="title">
            <g>
              <text
                transform="matrix(1.125 0 0 1 491.4263 1588.2253)"
                class="st1 st2 st3"
                :style="getFillColor(this.appData.introTitleColor)"
              >Title</text>
            </g>
          </g>
          <g id="descriptionText">
            <g>
              <text
                transform="matrix(1.0428 0 0 1 465.2371 1740.8287)"
                class="st1 st2 st4"
                :style="getFillColor(this.appData.introBodyColor)"
              >Description</text>
            </g>
          </g>
          <g id="skip">
            <text
              transform="matrix(1.1065 0 0 1 97.828 2255.5486)"
              class="st5 st6 st7"
            >SKIP</text>
          </g>
          <g id="next">
            <text
              transform="matrix(1.0883 0 0 1 941.6683 2255.5486)"
              class="st8 st6 st9"
            >NEXT</text>
          </g>
          <g id="light_x5F_dot">
            <circle
              class="st10"
              cx="457.6"
              cy="2243.2"
              r="13.3"
              :style="getFillColor(this.appData.introDotLightColor)"
            />
          </g>
          <g id="dark_x5F_dot">
            <circle
              class="st11"
              cx="496.2"
              cy="2243.2"
              r="13.3"
              :style="getFillColor(this.appData.introDotDarkColor)"
            />
            <circle
              class="st11"
              cx="536"
              cy="2243.2"
              r="13.3"
              :style="getFillColor(this.appData.introDotDarkColor)"
            />
            <circle
              class="st11"
              cx="578.3"
              cy="2243.2"
              r="13.3"
              :style="getFillColor(this.appData.introDotDarkColor)"
            />
            <circle
              class="st11"
              cx="615.5"
              cy="2243.2"
              r="13.3"
              :style="getFillColor(this.appData.introDotDarkColor)"
            />
            <circle
              class="st11"
              cx="653.3"
              cy="2243.2"
              r="13.3"
              :style="getFillColor(this.appData.introDotDarkColor)"
            />
            <circle
              class="st11"
              cx="690.8"
              cy="2243.2"
              r="13.3"
              :style="getFillColor(this.appData.introDotDarkColor)"
            />
          </g>
        </svg>
      </b-col>
      <b-col
        cols="12"
        md="8"
        lg="8"
      >
        <b-form>
          <b-row class="m-1">

            <!-- enable intros -->
            <b-col cols="12">
              <b-form-group>
                <b-form-checkbox
                  v-model="appData.showIntro"
                  switch
                >
                  Enable Intro Screens
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
            <!-- background color -->
            <b-col
              cols="12"
              class="mt-2"
            >
              <b-form-group
                label="Background Color"
                for="introBackgroundColor"
              >
                <input
                  id="introBackgroundColor"
                  v-model="appData.introBackgroundColor"
                  type="color"
                  class="color_input"
                  name="introBackgroundColor"
                >
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <hr>
            </b-col>
            <!-- text colors -->
            <b-col cols="12">
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    label="Title Text Color"
                    for="introTitleColor"
                  >
                    <input
                      id="introTitleColor"
                      v-model="appData.introTitleColor"
                      type="color"
                      class="color_input"
                      name="introTitleColor"
                    >
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Description Text Color"
                    for="descriptionTextColor"
                  >
                    <input
                      id="descriptionTextColor"
                      v-model="appData.introBodyColor"
                      type="color"
                      class="color_input"
                      name="introBodyColor"
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12">
              <hr>
            </b-col>
            <!-- dot colors -->
            <b-col cols="12">
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    label="Dot Color Dark"
                    for="introDotDarkColor"
                  >
                    <input
                      id="introDotDarkColor"
                      v-model="appData.introDotDarkColor"
                      type="color"
                      class="color_input"
                      name="introDotDarkColor"
                    >
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Dot Color Light"
                    for="introDotLightColor"
                  >
                    <input
                      id="introDotLightColor"
                      v-model="appData.introDotLightColor"
                      type="color"
                      class="color_input"
                      name="introDotLightColor"
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12">
              <hr>
            </b-col>
            <!-- start button label -->
            <b-col cols="12">
              <b-form-group
                label="Start Button Label"
                label-for="start-button-label"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="AlignLeftIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="start-button-label"
                    v-model="appData.start"
                    placeholder="LET'S GO"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- next button label -->
            <b-col cols="12">
              <b-form-group
                label="Start Button Label"
                label-for="next-button-label"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="AlignLeftIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="next-button-label"
                    v-model="appData.next"
                    placeholder="NEXT"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- start button label -->
            <b-col cols="12">
              <b-form-group
                label="Skip Button Label"
                label-for="skip-button-label"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="AlignLeftIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="skip-button-label"
                    v-model="appData.skip"
                    placeholder="SKIP"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <hr>
            </b-col>
            <b-col
              class="text-center mt-1"
              cols="12"
            >
              <b-button
                variant="outline-primary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="onAddClick()"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                Add Intro Screen
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <b-row>
          <draggable
            v-model="appData.introScreens"
            class="list-group list-group-flush cursor-move px-1 pb-2 w-100 mt-2"
            tag="ul"
          >
            <transition-group
              type="transition"
              name="flip-list"
            >
              <b-list-group-item
                v-for="(listItem, index) in appData.introScreens"
                :key="index +1"
                tag="li"
              >
                <div class="card">
                  <div class="card-horizontal">
                    <div class="img-square-wrapper">
                      <img
                        class="card-img"
                        :src="listItem.image"
                        alt="app intro image"
                      >
                    </div>
                    <div class="card-body align-middle">
                      <b-row class="align-middle">
                        <b-col
                          class="align-middle mb-2"
                          cols="12"
                          md="6"
                        >
                          <h4 class="card-title">
                            Title : {{ listItem.title }}
                          </h4>
                          <p class="card-text">
                            {{ listItem.description }}
                          </p>
                        </b-col>
                        <b-col
                          class="text-center align-middle"
                          cols="12"
                          md="6"
                        >
                          <div class="float-right  align-middle">
                            <b-button
                              class="ml-1 mr-1"
                              variant="secondary"
                              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                              @click="onUpdateClick(listItem,index)"
                            >
                              Update
                            </b-button>
                            <b-button
                              class="ml-1 mr-1"
                              variant="danger"
                              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                              @click="onRemoveClick(index)"
                            >
                              Remove
                            </b-button>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </b-list-group-item>
            </transition-group>
          </draggable>

        <!-- modal Intor-->

        </b-row>
      </b-col>

    </b-row>
    <b-modal
      id="modal-Intro"
      cancel-variant="outline-secondary"
      ok-title="Save"
      :ok-disabled="isImageUploadLoading"
      cancel-title="Close"
      centered
      :title="selectedIndex != -1 ? 'Update Intro Screen' :'Add Intro Screen'"
      @ok="selectedIndex != -1 ? onUpdateIntroSubmit():onAddIntroSubmit()"
    >
      <b-form>
        <b-form-group>
          <label for="title">Title:</label>
          <b-form-input
            id="title"
            v-model="modalIntroTitle"
          />
        </b-form-group>
        <image-upload
          text="Drop file here or click to upload Intro screen"
          :image-url="modalImage"
          @image-uploaded="imageUploaded"
        />
        <b-form-group>
          <label>Description</label>
          <b-form-input
            v-model="modalIntroDiscription"
            b-form-input
          /></b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BListGroupItem,
  BFormInput,
  BButton,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
// import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import ImageUpload from '../../components/ImageUpload.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    draggable,
    BListGroupItem,
    BFormCheckbox,
    ImageUpload,
    // Prism,
    BInputGroup,
    BInputGroupPrepend,
  },
  props: {
    appData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalIntroTitle: null,
      modalIntroDiscription: null,
      modalImage: null,
      introScreenImg: '',
      selectedIndex: -1,
      isImageUploadLoading: false,
    }
  },
  methods: {
    onAddClick() {
      this.selectedIndex = -1
      this.modalImage = null
      this.modalIntroTitle = null
      this.modalIntroDiscription = null
      this.$bvModal.show('modal-Intro')
    },
    onAddIntroSubmit() {
      const introScreen = { image: this.modalImage, title: this.modalIntroTitle, description: this.modalIntroDiscription }
      this.appData.introScreens.push(introScreen)
      this.modalImage = ''
      this.modalIntroTitle = ''
      this.modalIntroDiscription = ''
    },
    onUpdateIntroSubmit() {
      const introScreen = { image: this.modalImage, title: this.modalIntroTitle, description: this.modalIntroDiscription }
      this.$set(this.appData.introScreens, this.selectedIndex, introScreen)
    },
    onRemoveClick(index) {
      this.appData.introScreens.splice(index, 1)
    },
    onUpdateClick(item, index) {
      this.selectedIndex = index
      this.modalIntroTitle = item.title
      this.modalIntroDiscription = item.description
      this.modalImage = item.image
      this.$bvModal.show('modal-Intro')
    },
    imageUploaded(event) {
      this.modalImage = event
    },
    getFillColor(color) {
      return `fill:${color};`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
  .app_color_label{
    padding-right: 1rem;
    min-width:14rem;
    font-size: 14px;
    justify-self: center;
  }
  .color_input{
    width:50px;
    height: 50px;
  }
.list-group-item {
  transition: all 0.5s
}
.card-horizontal {
    display: flex;
    flex: 1 1 auto;
}
.list-group-item{
  padding: 0 !important;
}
.card{
  margin-bottom: 0;
}
.card-img{
  width:300px;
  height: 180px;
  padding: 32px;
}
.card-body{
  padding-top: 32px;
  align-self: center;
}
.image-container{
  margin: auto;
  justify-content: center;
  text-align: center;
}
.intro-description-image{
  max-height: 500px;
}
</style>
<style type="text/css">
.st0{fill:#FFFFFF;}
.st1{fill:#4A4A4C;}
.st2{font-family:'Helvetica';}
.st3{font-size:85.7926px;}
.st4{font-size:45px;}
.st5{fill:#545554;}
.st6{font-family:'MyriadPro-Regular';}
.st7{font-size:44.3057px;}
.st8{fill:#4056A4;}
.st9{font-size:45.5267px;}
.st10{fill:#DAD4E9;}
.st11{fill:#A092C5;}
</style>
