<template>
  <div>
    <div class="d-flex mb-2 ml-2">
      <h4 class="mb-0 ml-50">
        App Icon Preview
      </h4>
    </div>
    <b-row>
      <!-- Preview Section -->
      <b-col
        cols="12"
        md="4"
        lg="4"
        class="text-center"
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 1162.61 2324.32"
          style="enable-background:new 0 0 1162.61 2324.32; width: 75%; height: 75%;"
          xml:space="preserve"
        >
          <g id="background">
            <rect
              x="23.36"
              y="16.98"
              style="fill:#FFFFFF;"
              width="1090.21"
              height="2287.85"
            />
          </g>
          <g id="frame">

            <image
              style="overflow:visible;"
              width="698"
              height="1403"
              xlink:href="../../../assets/images/svg/frame.png"
              transform="matrix(1.6671 0 0 1.6567 -0.4198 -0.3542)"
            />
          </g>
          <g id="logo">
            <g>

              <image
                style="overflow:visible;opacity:0.06;"
                width="1277"
                height="1279"
                xlink:href="../../../assets/images/svg/icon.png"
                transform="matrix(0.24 0 0 0.24 96.84 314.52)"
              />
              <g>
                <g>
                  <g>
                    <defs>
                      <path
                        id="SVGID_1_"
                        d="M156.13,342.95h188.51c16.98,0,30.75,13.34,30.75,29.81v190.39c0,16.46-13.77,29.81-30.75,29.81H156.13 c-16.98,0-30.75-13.34-30.75-29.81V372.76C125.39,356.3,139.15,342.95,156.13,342.95z"
                      />
                    </defs>
                    <clipPath id="SVGID_2_">
                      <use
                        xlink:href="#SVGID_1_"
                        style="overflow:visible;"
                      />
                    </clipPath>
                    <g style="clip-path:url(#SVGID_2_);">

                      <image
                        style="overflow:visible;"
                        width="1232"
                        height="1280"
                        :xlink:href="appData.appLogoURL"
                        transform="matrix(0.2029 0 0 0.1953 125.3885 342.9511)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="name">
            <rect
              x="119.85"
              y="624.34"
              style="fill:none;"
              width="261.08"
              height="34.28"
            />
            <text
              transform="matrix(1 0 0 1 179.5198 645.9072)"
              style="font-family:'Helvetica'; font-size:30px;"
            >App Name</text>
          </g>
        </svg>
      </b-col>

      <!-- Configuration Section -->
      <b-col
        cols="12"
        md="8"
        lg="8"
      >
        <b-form>
          <b-row class="m-1">

            <!-- app icon -->
            <b-col cols="12">
              <image-upload
                text="Drop file here or click to upload app icon"
                :image-url="appData.appLogoURL"
                @image-uploaded="imageUploaded"
              />
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
            <!-- app details name -->
            <b-col cols="12">
              <b-form-group
                label="App Name"
                label-for="app-name"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="AwardIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="app-name"
                    v-model="appData.siteName"
                    type="text"
                    placeholder="My App"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- app details url -->
            <b-col cols="12">
              <b-form-group
                label="Website URL"
                label-for="site-url"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="GlobeIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="site-url"
                    v-model="appData.siteUrl"
                    type="text"
                    placeholder="My App"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- app details url scheme -->
            <b-col cols="12">
              <b-form-group
                label="URL Scheme"
                label-for="url-scheme"
              >
                <v-select
                  v-model="appData.urlScheme"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="schemeOptions"
                  label="text"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
            <!-- background color -->
            <b-col cols="12">
              <b-row>
                <b-col
                  cols="6"
                  class="mt-2"
                >
                  <b-form-group
                    label="App Primary Color"
                    for="primaryColor"
                  >
                    <input
                      id="primaryColor"
                      v-model="appData.primaryColor"
                      type="color"
                      class="color_input"
                      name="primaryColor"
                    >
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  class="mt-2"
                >
                  <b-form-group
                    label="App Primary Color Dark"
                    for="primaryColorDark"
                  >
                    <input
                      id="primaryColorDark"
                      v-model="appData.primaryDarkColor"
                      type="color"
                      class="color_input"
                      name="primaryColorDark"
                    >
                  </b-form-group>
                </b-col>
              </b-row>
              <b-col cols="12">
                <hr>
              </b-col>
              <b-row>
                <b-col
                  cols="6"
                  class="mt-2"
                >
                  <b-form-group
                    label="App Loading Indicator Color"
                    for="accentColor"
                  >
                    <input
                      id="accentColor"
                      v-model="appData.accentColor"
                      type="color"
                      class="color_input"
                      name="accentColor"
                    >
                  </b-form-group>
                </b-col>
              </b-row>
              <b-col cols="12">
                <hr>
              </b-col>
              <b-row>
                <b-col
                  cols="6"
                  class="mt-2"
                >
                  <b-form-group
                    label="Website Background Color"
                    for="websiteColor"
                  >
                    <input
                      id="websiteColor"
                      v-model="appData.websiteColor"
                      type="color"
                      class="color_input"
                      name="websiteColor"
                    >
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
      <b-col cols="12">
        <hr>
      </b-col>
    </b-row>
    <b-form>

      <b-row class="mt-2 w-100">

        <app-edit-config-details-card
          :controller.sync="appData.enableCenterLoading"
          heading="Center Loading"
          available-basic="true"
          available-standard="true"
          available-premium="true"
          description="This will show a loading indicator at the center of the screen when website is loading."
          @toggle-value="appData.enableCenterLoading=$event"
        />

        <app-edit-config-details-card
          :controller.sync="appData.enableTopLoading"
          heading="Top Loading"
          available-basic="true"
          available-standard="true"
          available-premium="true"
          description="This will show a loading indicator at the top of the screen when website is loading."
          @toggle-value="appData.enableTopLoading=$event"
        />

        <app-edit-config-details-card
          :controller.sync="appData.enablePDFView"
          heading="PDF view"
          available-basic="true"
          available-standard="true"
          available-premium="true"
          description="Enable this option if you want to open PDF files within the app without downloading as a file."
          @toggle-value="appData.enablePDFView=$event"
        />

        <app-edit-config-details-card
          :controller.sync="appData.enablePhoneDialer"
          heading="Phone Dialer"
          available-basic="true"
          available-standard="true"
          available-premium="true"
          description="Open phone dialer with the mobile number for tel: links"
          @toggle-value="appData.enablePhoneDialer=$event"
        />

        <app-edit-config-details-card
          :controller.sync="appData.enableWhatsAppShare"
          heading="WhatsApp Share"
          available-standard="true"
          available-premium="true"
          description="Open WhatsApp links in WhatsApp app"
          @toggle-value="appData.enableWhatsAppShare=$event"
        />

        <app-edit-config-details-card
          :controller.sync="appData.enableFileDownload"
          heading="File Download"
          available-standard="true"
          available-premium="true"
          description="Enable file download capability through the app"
          @toggle-value="appData.enableFileDownload=$event"
        />

        <app-edit-config-details-card
          :controller.sync="appData.enableFileUpload"
          heading="File Upload"
          available-premium="true"
          description="Enable file upload capability through the app. Users will be able to select/capture files/images from device and upload to website."
          @toggle-value="appData.enableFileUpload=$event"
        />

        <app-edit-config-details-card
          :controller.sync="appData.fullScreenApp"
          heading="Full Screen App"
          available-basic="true"
          available-standard="true"
          available-premium="true"
          description="Run app in fullscreen mode. This will hide action bar which shows battery percentage/signal strength on the top of the screen"
          @toggle-value="appData.fullScreenApp=$event"
        />

        <app-edit-config-details-card
          :controller.sync="appData.removeFooter"
          heading="Remove Footer"
          available-basic="true"
          available-standard="true"
          available-premium="true"
          description="Remove footer section of your website when showing in the app. This will enhance the native mobile look of your site"
          @toggle-value="appData.removeFooter=$event"
        />

        <app-edit-config-details-card
          :controller.sync="appData.enableCache"
          heading="Enable Cache"
          available-standard="true"
          available-premium="true"
          description="Keep cache of your site data for faster loading. But the pp size will grow over time."
          @toggle-value="appData.enableCache=$event"
        />

        <app-edit-config-details-card
          :controller.sync="appData.openExternalLinksInApp"
          heading="Open External Links in App"
          available-basic="true"
          available-standard="true"
          available-premium="true"
          description="Open links from external websites within the app without forwarding to the web browser"
          @toggle-value="appData.openExternalLinksInApp=$event"
        />

        <app-edit-config-details-card
          :controller.sync="appData.enableDeepLinking"
          heading="Deep Linking"
          available-premium="true"
          description="Recognize and open website links through app instead of web browser."
          @toggle-value="appData.enableDeepLinking=$event"
        />
        <app-edit-config-details-card
          :controller.sync="appData.preserveCookies"
          heading="Preserve Cookies"
          available-premium="true"
          description="Save cookies in the app to keep logged in user's without logging out."
          @toggle-value="appData.preserveCookies=$event"
        />

      </b-row>

    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
// import vue2Dropzone from 'vue2-dropzone'
import AppEditConfigDetailsCard from './AppEditConfigDetailsCard.vue'
import ImageUpload from '../../components/ImageUpload.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    AppEditConfigDetailsCard,
    ImageUpload,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    // vueDropzone: vue2Dropzone,
  },
  props: {
    appData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      schemeOptions: ['https', 'http'],
    }
  },
  setup(props) {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        // eslint-disable-next-line no-param-reassign
        props.appData.appLogoURL = base64
      },
    )

    return {
      avatarText,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  watch: {
    appData: {
      // watch it
      immediate: true,
      deep: true,
      handler(newValue) {
        console.log(newValue)
        console.log(this.$refs.dropzone)
      },
    },
  },
  methods: {
    imageUploaded(event) {
      this.appData.appLogoURL = event
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
.app_color_label {
  padding-right: 1rem;
  min-width: 12rem;
  font-size: 14px;
  justify-self: center;
}
.color_input {
  width: 100%;
  height: 30px;
}
.dz-progress {
  display: none !important;
}
#elevated-card {
  /* Add shadows to create the "card" effect */
  padding: 8px 16px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2) !important;
  transition: 0.3s !important;
}

#elevated-card.border-success {
  /* Add shadows to create the "card" effect */
    border: 2px solid #28c76f !important
}

/* On mouse-over, add a deeper shadow */
#elevated-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2) !important ;
}
@media (max-width: 560px) {
  .color-horizontal-align {
    padding-bottom: 1rem;
  }
}

</style>
