<template>
  <div>
    <div class="d-flex mb-2 ml-2">
      <h4>
        OneSignal Notification Setup
      </h4>
    </div>
    <b-form>
      <b-row class="m-1">

        <!-- enable notifications -->
        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox
                v-model="appData.enableNotification"
                switch
            >
              Enable OneSignal Push Notifications
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- admob app id -->
        <b-col cols="12">
          <b-form-group
              label="OneSignal App ID"
              label-for="onesignal-app-id"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="CodeIcon" />
              </b-input-group-prepend>
              <b-form-input
                  id="onesignal-app-id"
                  v-model="appData.oneSignalAppId"
                  placeholder="07589d2e-22b9-419e-8675-20596b9fd947"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
  },
  props: {
    appData: {
      type: Object,
      required: true,
    },
  },
  setup() {
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style lang="scss">
</style>
