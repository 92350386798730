<template>
  <!-- Field: Appname -->
  <b-col
    sm="12"
    md="4"
  >
    <b-card
      id="elevated-card-edit"
      no-body
      :border-variant="props.controller ? 'info' :'secondary'"
    >
      <b-card-header class="pb-1">
        <b-card-title>{{ props.heading }}</b-card-title>
        <b-form-checkbox
          :checked="props.controller"
          class="custom-control-info"
          :switch="true"
          :autofocus="true"
          @change="$emit('toggle-value',$event)"
        />
      </b-card-header>
      <b-card-header class="pb-1">
        <div class="design-group">
          <h6 class="section-label">
            AVAILABLE IN
          </h6>
          <b-badge
            :variant="availableBasic ? 'light-warning' : 'light-secondary'"
            class="mr-1"
          >
            Basic
            <feather-icon
              v-if="availableBasic"
              icon="CheckIcon"
            />
            <feather-icon
              v-if="!availableBasic"
              icon="XIcon"
            />
          </b-badge>
          <b-badge
            :variant="availableStandard ? 'light-primary' : 'light-secondary'"
            class="ml-25 mr-1"
          >
            Standard
            <feather-icon
              v-if="availableStandard"
              icon="CheckIcon"
            />
            <feather-icon
              v-if="!availableStandard"
              icon="XIcon"
            />
          </b-badge>
          <b-badge
            :variant="availablePremium ? 'light-success' : 'light-secondary'"
            class="ml-25"
          >
            Premium
            <feather-icon
              v-if="availablePremium"
              icon="CheckIcon"
            />
            <feather-icon
              v-if="!availablePremium"
              icon="XIcon"
            />
          </b-badge>
        </div>
      </b-card-header>
      <b-card-body>
        <h6 class="section-label mt-1">
          DESCRIPTION
        </h6>
        <b-card-text>{{ props.description }}</b-card-text>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {
  BCol,
  // BTable,
  BCard,
  BBadge,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {
    BCol,
    BCard,
    BBadge,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BFormCheckbox,
    // BFormSelect
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    controller: {
      type: Boolean,
      required: true,
    },
    availableBasic: {
      type: Boolean,
      required: true,
    },
    availableStandard: {
      type: Boolean,
      required: true,
    },
    availablePremium: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    // ? Demo Purpose => Update image on click of update
    console.log('props', props)
    return {
      props,
      avatarText,

    }
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
#elevated-card-edit {
  /* Add shadows to create the "card" effect */
  padding: 8px 16px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2) !important;
  transition: 0.3s !important;
}

#elevated-card-edit.border-success {
  /* Add shadows to create the "card" effect */
    border: 2px solid #28c76f !important
}

/* On mouse-over, add a deeper shadow */
#elevated-card-edit:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2) !important ;
}
@media (max-width: 560px) {
  .color-horizontal-align {
    padding-bottom: 1rem;
  }
}

</style>
