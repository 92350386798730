<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex mb-2 ml-2">
      <h4>
        AdMob Ad Settings
      </h4>
    </div>
    <b-form>
      <b-row class="m-1">

        <!-- enable ads -->
        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox
              v-model="appData.enableAdds"
              switch
            >
              Enable Ads
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- admob app id -->
        <b-col cols="12">
          <b-form-group
            label="Admob App ID"
            label-for="admob-app-id"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="CodeIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="admob-app-id"
                v-model="appData.admob_app_id"
                placeholder="ca-app-pub-3940256099942544~3347511713"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <hr>
        </b-col>
        <!-- enable top ads -->
        <b-col
          cols="12"
          class="mt-2"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="appData.topAdds"
              switch
            >
              Enable Top Banner Ads
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- enable bottom ads -->
        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox
              v-model="appData.bottomAdds"
              switch
            >
              Enable Bottom Banner Ads
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- admob app id -->
        <b-col cols="12">
          <b-form-group
            label="Admob Banner Ad ID"
            label-for="admob-banner-id"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="CodeIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="admob-banner-id"
                v-model="appData.admob_banner_id"
                placeholder="ca-app-pub-3940256099942544/6300978111"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <hr>
        </b-col>

        <!-- enable full ads -->
        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox
              v-model="appData.fullAdds"
              switch
            >
              Enable Full Screen Ads
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- admob full ad id -->
        <b-col cols="12">
          <b-form-group
            label="Admob Interstitial Ad ID"
            label-for="admob-interstitial-id"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="CodeIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="admob-interstitial-id"
                v-model="appData.admob_fullad_id"
                placeholder="ca-app-pub-3940256099942544/1033173712"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- ad delay -->
        <b-col cols="12">
          <b-form-group
            label="Full Screen Ad Delay in Seconds"
            label-for="full-ad-delay"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="ClockIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="full-ad-delay"
                v-model="appData.fullAddDelaySec"
                type="number"
                placeholder="60"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
  },
  props: {
    appData: {
      type: Object,
      required: true,
    },
  },
  setup() {
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style lang="scss">
</style>
