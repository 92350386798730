<template>
  <div>

    <!-- Header: Personal Info -->
    <div class="d-flex mb-2 ml-2">
      <h4>
        Splash Screen Preview
      </h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>

        <!-- Preview Section -->
        <b-col
          cols="12"
          md="4"
          lg="4"
          class="text-center"
        >
          <svg
            v-if="!appData.fullScreenSplash"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1162.61 2324.32"
            style="enable-background:new 0 0 1162.61 2324.32; width: 75%; height: 75%;"
            xml:space="preserve"
          >
            <g id="background">
              <rect
                x="28.58"
                y="18.66"
                :style="getSplashColor()"
                width="1078.93"
                height="2281.47"
              />
            </g>
            <g id="frame">

              <image
                style="overflow:visible;"
                width="698"
                height="1403"
                xlink:href="../../../assets/images/svg/frame.png"
                transform="matrix(1.6671 0 0 1.6567 -0.4198 -0.3542)"
              />
            </g>
            <g id="icon">

              <image
                style="overflow:visible;"
                width="512"
                height="512"
                :xlink:href="appData.splashScreen"
                transform="matrix(1.7408 0 0 1.5465 127.247 730.6428)"
              />
            </g>
          </svg>
          <svg
            v-if="appData.fullScreenSplash"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1162.61 2324.32"
            style="enable-background:new 0 0 1162.61 2324.32; width: 75%; height: 75%;"
            xml:space="preserve"
          >
            <g id="splash">
              <image
                style="overflow:visible;"
                width="1080"
                height="2280"
                :xlink:href="appData.splashScreen"
                transform="matrix(1 0 0 1 24.9527 22.1605)"
              />
            </g>
            <g id="frame">

              <image
                style="overflow:visible;"
                width="698"
                height="1403"
                xlink:href="../../../assets/images/svg/frame.png"
                transform="matrix(1.6671 0 0 1.6567 -0.4198 -0.3542)"
              />
            </g>
          </svg>

        </b-col>

        <!-- Config Section -->
        <b-col
          cols="12"
          md="8"
          lg="8"
        >
          <b-form>
            <b-row class="m-1">

              <!-- enable intros -->
              <b-col cols="12">
                <image-upload
                  text="Drop file here or click to upload splash screen"
                  :image-url="appData.splashScreen"
                  @image-uploaded="imageUploaded"
                />
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
              <!-- background color -->
              <b-col
                cols="12"
                class="mt-2"
              >
                <b-form-group
                  label="Screen Background Color"
                  for="screenBackgroundColor"
                >
                  <input
                    id="screenBackgroundColor"
                    v-model="appData.splashColor"
                    type="color"
                    class="color_input"
                    name="introBackgroundColor"
                  >
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <hr>
              </b-col>
              <!-- splash delay -->
              <b-col cols="12">
                <b-form-group
                  label="Splash Delay in milliseconds (1000 ms = 1s)"
                  label-for="splash-delay"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ClockIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="splash-delay"
                      v-model="appData.splashDisplayLength"
                      type="number"
                      placeholder="60"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
              <!-- enable full screen -->
              <b-col cols="12">
                <b-form-group>
                  <b-form-checkbox
                    v-model="appData.fullScreenSplash"
                    switch
                  >
                    Show Image in Full Screen Mode
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
      <!-- Form: Personal Info Form -->
      <b-row class="mt-1" />

      <b-row class="mt-2">
        <b-col>
          <!-- <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            Reset
          </b-button> -->
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend,
  // BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ImageUpload from '../../components/ImageUpload.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    ImageUpload,
    BInputGroup,
    BInputGroupPrepend,
  },
  props: {
    appData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    imageUploaded(event) {
      this.appData.splashScreen = event
    },
    getSplashColor() {
      return `fill:${this.appData.splashColor};`
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
  .app_color_label{
    padding-right: 1rem;
    font-size: 14px;
    justify-self: center;
  }
  .color_input{
    width:50px;
    height: 50px;
  }
.dark-layout  .vuedropzone {
    background-color: $theme-dark-input-bg;
  }
  .dropzone.dz-clickable {
    cursor: pointer;
    text-align: center;
}
</style>
