<template>
  <component :is="appData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="appData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs
      v-if="appData"
      v-model="tabIndex"
      pills
    >

      <!-- Tab: Account -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="SettingsIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Basic Information</span>
        </template>
        <app-edit-basic-tab
          :app-data="appData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="ImageIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Splash Screen</span>
        </template>
        <app-edit-splash-information
          :app-data="appData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Social -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="LayersIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Intro Screens</span>
        </template>
        <app-edit-intro-screens
          :app-data="appData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="TvIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Advertisements</span>
        </template>
        <app-edit-advertisements
          :app-data="appData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="BellIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Notifications</span>
        </template>
        <app-edit-notifications
          :app-data="appData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      :disabled="isLoading"
      @click="onSaveClick()"
    >
      <b-spinner
        v-if="isLoading"
        small
      />
      Save Changes
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="onCancelClick()"
    >
      Cancel
    </b-button>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BButton, BSpinner,
} from 'bootstrap-vue'
import {
  ref,
  // onUnmounted
  watch, computed,
} from '@vue/composition-api'

import router from '@/router'
import store from '@/store'
// import userStoreModule from '../userStoreModule'
import AppEditBasicTab from './AppEditBasicTab.vue'
import AppEditSplashInformation from './AppEditSplashInformation.vue'
import AppEditIntroScreens from './AppEditIntroScreens.vue'
import AppEditAdvertisements from './AppEditAdvertisements.vue'
import AppEditNotifications from './AppEditNotifications.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BSpinner,
    AppEditBasicTab,
    AppEditSplashInformation,
    AppEditIntroScreens,
    AppEditAdvertisements,
    AppEditNotifications,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  setup() {
    const tabIndex = ref(0)
    if (router.currentRoute?.query?.mode === 'splash') {
      tabIndex.value = 1
    }
    if (router.currentRoute?.query?.mode === 'info') {
      tabIndex.value = 2
    }
    if (router.currentRoute?.query?.mode === 'adds') {
      tabIndex.value = 3
    }
    if (router.currentRoute?.query?.mode === 'notifications') {
      tabIndex.value = 4
    }
    // const USER_APP_STORE_MODULE_NAME = 'app-user'

    // // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    // })
    console.log(router.currentRoute.params.id)

    store.dispatch('appSite/fetchSelectedApp', { id: router.currentRoute.params.id })
      .then(() => {
        watch(store.getters['appSite/selectedApp'], () => {
          console.log('value changes detected')
        })
      })
      .catch(error => {
        console.log(error)
      })

    return {
      appData: computed(() => store.getters['appSite/selectedApp']),
      tabIndex,
    }
  },
  computed: {
    loadingStatus() {
      return this.$store.getters['loading/loadingStatus']
    },
  },
  watch: {
    loadingStatus(payload) {
      this.isLoading = payload.isLoading
    },
  },
  methods: {
    onSaveClick() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Confirm',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('appSite/updateSelectedApp', { id: router.currentRoute.params.id, appData: { ...this.appData } })
          // this.$swal({
          //   icon: 'success',
          //   title: 'Success',
          //   text: 'Your data has been Updated Successfully.',
          //   customClass: {
          //     confirmButton: 'btn btn-success',
          //   },
          // })
        }
      })
    },
    onCancelClick() {
      this.$router.push(`/view-app/${router.currentRoute.params.id}`)
    },
  },
}
</script>

<style>
 .dark-layout .swal2-container .swal2-modal {
    background-color: #283046;
}
.dark-layout .swal2-container .swal2-modal .swal2-content {
    color: #b4b7bd;
}
</style>
